import React, { Component } from "react";
import NavigationItems from "../NavigationItems/NavigationItems";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { format, parse } from "date-fns";
import cookie from "react-cookies";
import NPOPlayer from "@npo/player";
import axios from "axios";
import * as Sentry from "@sentry/react";

import Loader from "../../components/UI/Loader/Loader";
import NewsItems from "../../components/NewsItems/NewsItems";
import SterBanner from "../../components/UI/SterBanner/SterBanner";
import NPOLogo from "../../assets/images/logo-npo.svg";
import Frequencies from "../../components/Frequencies/Frequencies";
import Player from "../Player/Player";

import Header from "../../components/Header/Header";
import PlayedTracks from "../../components/PlayedTracks/PlayedTracks";
import BroadcastItems from "../../components/BroadcastItems/BroadcastItems";
import TopSpin from "../../components/TopSpin/TopSpin";
import SubChannelSelector from "../../components/Player/SubChannelSelector/SubChannelSelector";


const getPlayerToken = async (channel) => {
    const tokenUrl = await axios({
        url: process.env.REACT_APP_API_ENDPOINT,
        method: "post",
        data: {
            query: `query Tokens($channelSlug: String!) {
                core_channels(slug: $channelSlug) {
                    data {
                        live_audio {
                            token_url
                        }
                        live_video {
                            token_url
                        }
                    }
                }
            }`,
            variables: { channelSlug: channel },
        },
    });
    return tokenUrl.data.data.core_channels.data[0];
};

class Layout extends Component {
    state = {
        isLoaded: false,
        isSubChannelsVisible: false,
        broadcast: null,
        broadcasts: null,
        track: null,
        tracks: null,
        news: null,
        isAudioPlayerVisible: true,
        isVideoPlayerVisible: false,
        bannerHasConsent: false,
        // Also used to determine initial load
        firstPage: true,
    };

    /**
     * Notify TopSpin of changes in the url.
     * Also update the PlayedTracks when there's an update to the nowLive track
     *
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        if (
            (this.props.location !== prevProps.location &&
                prevProps.location.pathname !== "/") ||
            this.state.firstPage === true
        ) {
            const path =
                this.props.location.pathname === "/"
                    ? "index"
                    : this.props.location.pathname;
            TopSpin.doCount(path.replace(/\//g, ""), this.props.config.topSpin);

            // Depending on which page we are loading, refresh the data
            switch (path) {
                case "/gedraaid":
                    this.handleRefreshNowPlaying();
                    break;
                case "/gids":
                    this.handleRefreshBroadcasts();
                    break;
                case "/nieuws":
                    this.handleRefreshNewsItems();
                    break;
                default:
                    // do nothing
                    break;
            }
        }

        if (this.state.firstPage === true) {
            this.setState({
                firstPage: prevProps.location.pathname,
            });
        }
    }

    audioPlayToggleHandler = ({ retryMode }) => {
        if (retryMode) {
            // determine if the player is playing or not
            window.NpoPlayer?.player?.play()
            this.setState(() => {
                return {
                    isVideoPlayerVisible: false,
                    isAudioPlayerVisible: true,
                };
            });
            return
        }

        this.state.isAudioPlayerVisible
            ? window.NpoPlayer?.player?.pause()
            : window.NpoPlayer?.player?.play();


        this.setState((prevState) => {
            return {
                isVideoPlayerVisible: false,
                isAudioPlayerVisible: !prevState.isAudioPlayerVisible,
            };
        });
    };

    videoPlayToggleHandler = async () => {
        // We always disable the audio player
        window.NpoPlayer?.player?.pause();

        this.setState((prevState) => {
            if (!prevState.isVideoPlayerVisible) {
                TopSpin.doClick({
                    click_type: "action",
                    click_chapter_1: "popupplayer",
                    click_chapter_2: this.props.config.topSpin.chapter_2,
                    click_name: "kijklive",
                });

                setTimeout(
                    () => this._div.scrollIntoView({ behavior: "smooth" }),
                    200
                );
            }

            return {
                isVideoPlayerVisible: !prevState.isVideoPlayerVisible,
                isAudioPlayerVisible: false,
            };
        });

        if (!this.state.isVideoPlayerVisible) {
            const videoToken = await axios.get(
                this.state.playerToken.live_video.token_url
            );

            let options = {
                analytics: { key: process.env.REACT_APP_BITMOVIN_ANALYTICS_KEY },
                key: process.env.REACT_APP_BITMOVIN_TOKEN,
                playback: { autoplay: true },
            };
            const container = document.getElementById("video-player");
            const player = new NPOPlayer(
                container,
                options,
                window.NpoTagContext
            );
            player.loadStream(videoToken.data.playerToken);
        }
    };

    subchannelsToggleHandler = () => {
        this.setState((prevState) => {
            return {
                isSubChannelsVisible: !prevState.isSubChannelsVisible,
            };
        });
    };

    handleRefreshBroadcasts = () => {
        axios
            .get(this.props.config.broadcastsPath)
            .then((response) => {
                const data =
                        response.data.data[0] || this.props.config.fallback,
                    time = data.startdatetime
                        ? format(parse(data.startdatetime), "HH:mm") +
                          " - " +
                          format(parse(data.stopdatetime), "HH:mm")
                        : null;

                this.setState({
                    broadcast: {
                        ...data,
                        time: time,
                        fallback_image:
                            this.props.config.fallback.image_url_400x400,
                    },
                    broadcasts: response.data.data.length
                        ? response.data.data.slice(1, 15)
                        : [],
                });
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    handleRefreshNowPlaying = () => {
        if (this.props.config.showTracks) {
            axios
                .get(this.props.config.tracksPath)
                .then((response) => {
                    this.setState({
                        tracks: response.data.data.map((el) => ({
                            ...el,
                            fallbackImage:
                                this.props.config.fallback.image_url_400x400,
                        })),
                    });
                })
                .catch((error) => {
                    Sentry.captureException(error);
                });
        }
    };

    handleRefreshNewsItems = () => {
        axios
            .get(this.props.config.newsPath)
            .then((response) => {
                this.setState({ news: response.data.data.slice(0, 15) });
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    /**
     * Helper function to add a script only once to the body
     *
     * @param   url The url of the script to add
     * @returns void
     */
    addScript = (url) => {
        const scripts = document.getElementsByTagName("script");

        for (let i = scripts.length; i--; ) {
            if (scripts[i].src === url) {
                return;
            }
        }

        const script = document.createElement("script");

        script.src = url;
        script.async = true;

        document.head.appendChild(script);
    };

    componentDidMount() {
        const conf = window.config;

        getPlayerToken(conf.channelSlug).then((data) => {
            this.setState({
                playerToken: data,
            });
        });

        window.addEventListener(
            "CCM_Wrapper_ready",
            () => {
                let consent = false;

                // Don't show CCM on dev environments
                if (
                    window.location.hostname === "localhost" ||
                    window.location.hostname.indexOf("ngrok") !== -1
                ) {
                    this.setState({
                        bannerHasConsent: true,
                    });
                    consent = true;
                } else {
                    window.ccmWrapper.init();
                    consent = cookie.load("Cookie_Category_Advertising");
                }

                // Show Banner either when we have consent or when it's forced via the configuration
                if (consent || this.props.config.forceBanner) {
                    this.addScript(conf.bannerUrl);

                    this.setState({
                        bannerHasConsent: true,
                    });
                }
            },
            false
        );

        this.addScript(conf.cookieScript);

        this.handleRefreshBroadcasts();
        setInterval(this.handleRefreshBroadcasts, 600 * 1000);

        this.handleRefreshNowPlaying();
        setInterval(this.handleRefreshNowPlaying, 30 * 1000);

        this.handleRefreshNewsItems();
        setInterval(this.handleRefreshNewsItems, 600 * 1000);

        this.setState({
            config: conf,
            isLoaded: true,
        });
    }

    render() {
        let header = (
            <Header
                broadcast={this.state.broadcast}
                track={this.state.track}
                showBroadcaster={
                    this.props.config.showBroadcaster === undefined ||
                    this.props.config.showBroadcaster === true
                }
            />
        );

        if (this.state.isVideoPlayerVisible) {
            header = (
                <div className="video-container">
                    <div id="video-player"></div>
                </div>
            );
        }

        if (!this.state.isLoaded) {
            return <Loader />;
        }

        return (
            <div className={this.props.config.bodyClass}>
                <div className="wrapper">
                    <header className="top" role="banner">
                        <a
                            href={this.props.config.channelPath}
                            className="logo-container"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <span
                                className="logo"
                                title={this.props.config.channel}
                            />
                        </a>
                        {this.props.config.subChannels.length ? (
                            <div className="subchannel-container">
                                <button
                                    className="subchannel-button"
                                    onClick={this.subchannelsToggleHandler}
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.config
                                            .subChannelLabel,
                                    }}
                                />
                                <SubChannelSelector
                                    channels={this.props.config.subChannels}
                                    show={this.state.isSubChannelsVisible}
                                    closed={this.subchannelsToggleHandler}
                                />
                            </div>
                        ) : null}
                        <a
                            href="https://over.npo.nl/?ns_campaign=meernpo&ns_mchannel=omroep&ns_source=nporadio&ns_linkname=button"
                            className="npo-container"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <img
                                className="npo-logo"
                                src={NPOLogo}
                                alt="Logo NPO"
                            />
                        </a>
                    </header>
                    <div className="scrollable">
                        <div ref={(ref) => (this._div = ref)} />
                        {header}
                        <nav className="navigation" role="navigation">
                            <NavigationItems
                                items={this.props.config.navigationItems}
                            />
                        </nav>
                        <main className="main" role="main">
                            <div className="grid">
                                {this.state.bannerHasConsent ? (
                                    <div className="grid__item">
                                        <SterBanner
                                            sterdiv={this.props.config.bannerID}
                                        />
                                    </div>
                                ) : null}
                                <div className="grid__item">
                                    <Switch>
                                        <Route
                                            exact
                                            path="/gids"
                                            render={() => (
                                                <BroadcastItems
                                                    broadcasts={
                                                        this.state.broadcasts
                                                    }
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/programmering"
                                            render={() => (
                                                <BroadcastItems
                                                    broadcasts={
                                                        this.state.broadcasts
                                                    }
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/nieuws"
                                            render={() => (
                                                <NewsItems
                                                    newsItems={this.state.news}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/frequenties"
                                            render={() => (
                                                <Frequencies
                                                    config={
                                                        this.props.config
                                                            .frequencies
                                                    }
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/subchannels"
                                            render={() => (
                                                <Frequencies
                                                    config={
                                                        this.props.config
                                                            .frequencies
                                                    }
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/gedraaid"
                                            render={() => (
                                                <PlayedTracks
                                                    tracks={this.state.tracks}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/tracks"
                                            render={() => (
                                                <PlayedTracks
                                                    tracks={this.state.tracks}
                                                />
                                            )}
                                        />
                                        <Redirect
                                            to={
                                                this.props.config
                                                    .navigationItems[0].link +
                                                window.location.hash
                                            }
                                        />
                                    </Switch>
                                </div>
                            </div>
                        </main>
                    </div>
                    <section className="player">
                        {this.state.playerToken && (
                            <Player
                                config={this.props.config}
                                isVideoActive={this.state.isVideoPlayerVisible}
                                isAudioActive={this.state.isAudioPlayerVisible}
                                onAudioClicked={this.audioPlayToggleHandler}
                                onVideoClicked={this.videoPlayToggleHandler}
                                playerTokens={this.state.playerToken}
                            />
                        )}
                    </section>
                </div>
            </div>
        );
    }
}

export default withRouter(Layout);
