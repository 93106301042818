import {TagPlugin} from '../tagPlugin';
import {Event} from '../../event';
import {Queue} from './queue';
import {sleep} from '../../utils/time';
import {debug, warn} from '../../utils/log';
import {isLocalStorageAvailable} from '../../utils/localStorage';
import {govolteEventFromEvent, GovolteEventQueryParams} from './govolteEvent';
import qs from 'qs';
import {EventType} from '../../eventType';
import {newStreamWaypointEventThrottler} from '../../streamWaypointEventThrottler';
import {throttleEvent} from '../../eventThrottler';

const TAG = 'GovoltePlugin';

const GOVOLTE_DEFAULT_BASE_URL = 'https://topspin.npo.nl';
const GOVOLTE_ENDPOINTS = {
  submitEvent: 'web-event',
};

export const STORAGE_NAME = 'Govolte_queue';

export const REQUEST_TIMEOUT_SECONDS = 5_000;

export const DEFAULT_DELAY_BETWEEN_RETRY_IN_MS = 5_000;
export const DEFAULT_MAX_RETRY_COUNT = 3;
export const DEFAULT_DELAY_BETWEEN_STREAM_WAYPOINTS_IN_MS = 30_000;

const MAX_DELAY_BETWEEN_RETRY_IN_MS = 30_000;
const MIN_DELAY_BETWEEN_RETRY_IN_MS = 0;

const MAX_MAXIMUM_RETRY_COUNT = 10;
const MIN_MAXIMUM_RETRY_COUNT = 0;

const MAX_DELAY_BETWEEN_STREAM_WAYPOINTS_IN_MS = 30_000;
const MIN_DELAY_BETWEEN_STREAM_WAYPOINTS_IN_MS = 0;

/**
 * Govolte plugin
 * - Includes a queue system where it tries to resend any failed event to
 *   Govolte URL as many as {@link maxRetryCount}
 */
export interface GovoltePlugin extends TagPlugin {
  /**
   * Return of all events that are not yet sent
   */
  queuedEvents(): GovolteEventQueryParams[];

  /**
   * True if plugin is busy with sending an event (actually sending object through API
   * or waiting to retry sending failed API event)
   */
  isSendingEvent(): boolean;

  /**
   * Max number of retries before ditching an event.
   */
  maxRetryCount: number;

  /**
   * Number of ms between each retry call.
   */
  delayBetweenRetriesInMs: number;

  /**
   * Minimum number of ms between a stream-related event and a `streamWaypoint` event
   */
  delayBetweenStreamWaypointsInMs: number;
}

/**
 * Options object for {@link GovoltePlugin}
 * @property maxRetryCount max number of retries before ditching an event.
 *   For example, if you set it to 3 then it will do 4 trials.
 *   Value should be between 0 and 10.
 * @property delayBetweenRetriesInMs number of ms between each retry call.
 *   It will be multiplied by the retry count so that each retry waits for a little longer.
 *   Value should be between 0 and 30_000.
 * @property delayBetweenStreamWaypointsInMs number of ms between `streamWaypoint` events.
 *   Other stream-related events will also reset this interval.
 *   Value should be between 0 and 30_000
 * @property baseUrl destination url to which events will be sent.
 */
export type GovoltePluginOptions = {
  maxRetryCount: number;
  delayBetweenRetriesInMs: number;
  delayBetweenStreamWaypointsInMs: number;
  baseUrl: string;
};

const DEFAULT_GOVOLTE_OPTIONS: GovoltePluginOptions = {
  maxRetryCount: DEFAULT_MAX_RETRY_COUNT,
  delayBetweenRetriesInMs: DEFAULT_DELAY_BETWEEN_RETRY_IN_MS,
  delayBetweenStreamWaypointsInMs: DEFAULT_DELAY_BETWEEN_STREAM_WAYPOINTS_IN_MS,
  baseUrl: GOVOLTE_DEFAULT_BASE_URL,
};

/**
 * Event that includes how many times the trial has been done
 */
interface QueueEvent extends GovolteEventQueryParams {
  trialCount: number;
}

/**
 * Instantiate new Govolte plugin
 * @param options Optional configuration options (see: {@link GovoltePluginOptions})
 * @returns A configured {@link GovoltePlugin} object
 */
export function newGovoltePlugin(
  options?: Partial<GovoltePluginOptions>
): GovoltePlugin {
  const pluginOptions: GovoltePluginOptions = {
    ...DEFAULT_GOVOLTE_OPTIONS,
    ...options,
  };

  const eventThrottlers = [
    newStreamWaypointEventThrottler(
      pluginOptions.delayBetweenStreamWaypointsInMs
    ),
  ];

  // Make sure that maxRetryCount, delayBetweenRetriesInMs and delayBetweenStreamTimeEventsInMs
  // are between the min and max allowed values
  const maxRetryCount = pluginOptions.maxRetryCount!;
  if (maxRetryCount < MIN_MAXIMUM_RETRY_COUNT) {
    throw Error(
      `Invalid maxRetryCount(${maxRetryCount}). Minimum maxRetryCount is ${MIN_MAXIMUM_RETRY_COUNT}.`
    );
  }

  if (maxRetryCount > MAX_MAXIMUM_RETRY_COUNT) {
    throw Error(
      `Invalid maxRetryCount(${maxRetryCount}). Maximum maxRetryCount is ${MAX_MAXIMUM_RETRY_COUNT}.`
    );
  }

  const delayBetweenRetriesInMs = pluginOptions.delayBetweenRetriesInMs!;
  if (delayBetweenRetriesInMs < MIN_DELAY_BETWEEN_RETRY_IN_MS) {
    throw Error(
      `Invalid delayBetweenRetriesInMs(${delayBetweenRetriesInMs}). Minimum delayBetweenRetriesInMs is ${MIN_DELAY_BETWEEN_RETRY_IN_MS}.`
    );
  }

  if (delayBetweenRetriesInMs > MAX_DELAY_BETWEEN_RETRY_IN_MS) {
    throw Error(
      `Invalid delayBetweenRetriesInMs(${delayBetweenRetriesInMs}). Maximum delayBetweenRetriesInMs is ${MAX_DELAY_BETWEEN_RETRY_IN_MS}.`
    );
  }

  const delayBetweenStreamWaypointsInMs =
    pluginOptions.delayBetweenStreamWaypointsInMs!;
  if (
    delayBetweenStreamWaypointsInMs < MIN_DELAY_BETWEEN_STREAM_WAYPOINTS_IN_MS
  ) {
    throw Error(
      `Invalid delayBetweenStreamTimeEventsInMs(${delayBetweenStreamWaypointsInMs}). Minimum delayBetweenStreamTimeEventsInMs is ${MIN_DELAY_BETWEEN_STREAM_WAYPOINTS_IN_MS}.`
    );
  }

  if (
    delayBetweenStreamWaypointsInMs > MAX_DELAY_BETWEEN_STREAM_WAYPOINTS_IN_MS
  ) {
    throw Error(
      `Invalid delayBetweenStreamTimeEventsInMs(${delayBetweenStreamWaypointsInMs}). Maximum delayBetweenStreamTimeEventsInMs is ${MAX_DELAY_BETWEEN_STREAM_WAYPOINTS_IN_MS}.`
    );
  }

  const queue = new Queue<QueueEvent>();
  let isSendingEvent = false;

  /**
   * Load events from local storage if it's available
   */
  function loadPreviousEventsFromLocalStorage() {
    if (!isLocalStorageAvailable()) {
      warn(
        TAG,
        "Local storage is unavailable. Event queue won't be saved to local storage."
      );
      return;
    }
    const prevItems = localStorage.getItem(STORAGE_NAME);
    if (!prevItems) {
      return;
    }
    const parsedItems = JSON.parse(prevItems) as QueueEvent[];
    queue.enqueue(...parsedItems);
  }

  /**
   * Save events to local storage
   * @param events
   */
  function saveEventsToLocalStorage(events: QueueEvent[]) {
    if (!isLocalStorageAvailable()) {
      return;
    }
    localStorage.setItem(STORAGE_NAME, JSON.stringify(events));
  }

  /**
   * This is a workaround to perform a GET request without getting blocked by ad-blockers
   *
   * @param url to call
   */
  async function requestAsImage(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      // If the request took longer than we expect, just assume that it failed.
      const cancellation = setTimeout(() => {
        cancelled = true;
        reject('Sending event took too long.');
      }, REQUEST_TIMEOUT_SECONDS);
      const image = new Image(1, 1);
      let cancelled = false;
      image.onload = () => {
        if (!cancelled) {
          clearTimeout(cancellation);
          resolve();
        }
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      image.onerror = (e: any) => reject(e);
      image.src = url;
    });
  }

  /**
   * Send event to govolte
   * @param govolteEvent Govolte event query params {@link GovolteEventQueryParams}
   * @return true if event is successfully sent
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function sendEvent(
    govolteEvent: GovolteEventQueryParams
  ): Promise<boolean> {
    const queryParams = qs.stringify(govolteEvent);
    const url = `${pluginOptions.baseUrl}/${GOVOLTE_ENDPOINTS.submitEvent}?${queryParams}`;
    try {
      await requestAsImage(url);
      return true;
    } catch (err) {
      warn('Error delivering event', err);
      return false;
    }
  }

  /**
   * Manage event queue by trying to send one event at a time to govolte
   * @param isTriggeredFromNewEvent false if this is the same event trying to be re-sent, defaults to true.
   */
  function handleQueue(isTriggeredFromNewEvent = true) {
    // Don't try to send another event when the plugin is busy sending another event
    // Only send one event at a time
    if (isSendingEvent && isTriggeredFromNewEvent) {
      return;
    }

    // Check if we have any events in the queue
    const event = queue.peek();
    if (!event) {
      return;
    }

    // Destructure the event to a GovolteEventQueryParams object
    const {trialCount: savedTrialCount, ...govolteEvent} = event;

    // Increment the event trial count and update it in the queue
    const trialCount = isNaN(savedTrialCount) ? 1 : savedTrialCount + 1;
    queue.swapTopElement({
      ...govolteEvent,
      trialCount,
    });
    saveEventsToLocalStorage(queue.getAllItems());

    debug(
      TAG,
      `Sending event [attempt ${trialCount}]`,
      govolteEvent.t,
      govolteEvent
    );

    // If there's an event, then actually send it
    isSendingEvent = true;
    sendEvent(govolteEvent).then(isSuccessful => {
      // If the event is successfully sent or has already been retried
      // more than `maxRetryCount` times, then remove it from queue
      if (isSuccessful || trialCount > maxRetryCount) {
        isSendingEvent = false;

        if (isSuccessful) {
          debug(TAG, `Successfully sent event [attempt ${trialCount}]`);
        } else {
          debug(
            TAG,
            `Removing event because already tried sending ${trialCount} times. MaxRetryCount is ${maxRetryCount}.`
          );
        }
        queue.dequeue();
        saveEventsToLocalStorage(queue.getAllItems());
        handleQueue(false);
      } else {
        const delayDuration = delayBetweenRetriesInMs * trialCount;
        debug(
          TAG,
          `Failed sending event. Now waiting for ${delayDuration}ms before sending another event.`
        );
        sleep(delayDuration).then(() => handleQueue(false));
      }
    });
  }

  // Load unsent events
  loadPreviousEventsFromLocalStorage();
  // Then try to send it again
  handleQueue(false);

  return {
    submitEvent(eventType: EventType, event: Event) {
      // Check if the event should be sent or throttled
      if (throttleEvent(eventType, event, eventThrottlers)) {
        return;
      }

      // Convert event to govolte event
      const govolteEvent = govolteEventFromEvent(eventType, event);

      // Queue the event for sending
      const trialCountEvent: QueueEvent = {
        ...govolteEvent,
        trialCount: 0,
      };
      queue.enqueue(trialCountEvent);
      saveEventsToLocalStorage(queue.getAllItems());
      handleQueue();
    },

    queuedEvents(): GovolteEventQueryParams[] {
      return queue.getAllItems();
    },

    isSendingEvent(): boolean {
      return isSendingEvent;
    },

    delayBetweenRetriesInMs,

    maxRetryCount,

    delayBetweenStreamWaypointsInMs,

    // all our session data (like party_id, session_id) is already in the NPOTag class
    getSessionInfo: () => ({}),
    initializeFromSessionInfo() {},
  };
}
