import React from 'react';
import PropTypes from 'prop-types'

const Broadcast = ({broadcast, showBroadcaster}) => {
	const image = broadcast.image_url_400x400 && broadcast.image_url_400x400 !== 'null' ?
		broadcast.image_url_400x400 : broadcast.fallback_image;

	const broadcasters = broadcast.broadcasters.map(broadcaster => { return broadcaster.name; }).join(" / ");
	const broadcastersalias = broadcast.broadcasters.map(broadcaster => { return broadcaster.alias; }).join("-");

	const broadcastersTag = <div key={broadcastersalias}>
			<div className="show__broadcaster">{broadcasters}</div>
		</div>;

	return (
		<>
			<div className="header__image">
				<div className="image-placeholder image-placeholder--1-1">
					<div className="image" style={{backgroundImage: 'url(' + image + ')'}}/>
				</div>
			</div>
			<div className="header__body">
				<div className="show">
					<div className="show__content">
						<div className="show__top">
							<div className="show__name overflow-ellipsis">{broadcast.title}</div>
							{showBroadcaster &&
								<>
									<span className="show__divide"></span>
									{broadcastersTag}
								</>
							}
						</div>
						{broadcast.time &&
							<div className="show__time">
								{broadcast.time}
							</div>
						}
					</div>
				</div>
			</div>
			<div className="header__footer">
				<div>{broadcast.presenters}</div>
			</div>
		</>
	);
};

Broadcast.propTypes = {
	broadcast: PropTypes.shape({
		presenterImage: PropTypes.string,
		title: PropTypes.string.isRequired,
		time: PropTypes.string
	}),
	broadcasters: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			alias: PropTypes.string
		})
	)

};

export default Broadcast;
