import React, { Component } from "react";
import PropTypes from "prop-types";
import Appje from "../../components/Appje/Appje";
import VolumeSlider from "../../components/Player/VolumeSlider/VolumeSlider";
import SubChannelSelector from "../../components/Player/SubChannelSelector/SubChannelSelector";
import NPOPlayer from "@npo/player";
import axios from "axios";
import TopSpin from "../../components/TopSpin/TopSpin";

class Player extends Component {
    state = {
        volume: 100,
        isAppjeVisible: false,
        isSubChannelsVisible: false,
        isVolumeSliderVisible: false,
        isAudioPlayerVisible: false,
        isVideoPlayerVisible: false,
        isAudioPlayerActive: false,
        retryMode: false,
    };

    async getAudioToken() {
        const audioToken = await axios.get(
            this.props.playerTokens.live_audio.token_url
        );
        return audioToken.data.playerToken;
    }

    hasAppjeInUrl = () => {
        return window.location.hash === '#appje';
    };

    componentDidMount() {
        const options = {
            analytics: { key: process.env.REACT_APP_BITMOVIN_ANALYTICS_KEY },
            key: process.env.REACT_APP_BITMOVIN_TOKEN,
            playback: { autoplay: !this.hasAppjeInUrl() },
        };

        const container = document.getElementById("audio-player");

        if (!options.playback.autoplay) {
            this.setState(() => ({ retryMode: true }));
        }

        this.getAudioToken()
        .then((data) => {
            TopSpin.initialize();
            window.NpoPlayer = new NPOPlayer(
                container,
                options,
                window.NpoTagContext
            );
            return window.NpoPlayer.loadStream(data);
        }).then(() => {
            // set the state to true when the player is ready to play
            window.NpoPlayer?.player?.on('ready', () => {
                window.NpoPlayer.player.play().then(() => {
                    this.setState(() => ({ isAudioPlayerActive: true }));
                }).catch(() => {
                    this.setState(() => ({ retryMode: true }));
                });
            });
        })
    }

    componentWillUnmount() {
        window.NpoPlayer?.player?.destroy();
    }

    resetState = () => {
        // check if the player is playing or not
        this.props.onAudioClicked({ retryMode: this.state.retryMode });

        // set the resetState to false so the player can be played again
        this.setState(() => ({
            retryMode: false,
        }))
        this.setState(() => ({ isAudioPlayerActive: true }));

    }

    appjeToggleHandler = () => {
        this.setState((prevState) => {
            if (!prevState.isAppjeVisible && window.npotag !== undefined) {
                TopSpin.doClick({
                    click_type: "action",
                    click_chapter_1: "popupplayer",
                    click_chapter_2: this.props.config.topSpin.chapter_2,
                    click_name: "appdestudio",
                });
            }

            if (this.hasAppjeInUrl()) {
                window.location.hash = '';
            } else {
                window.location.hash = 'appje';
            }

            return {
                isAppjeVisible: this.hasAppjeInUrl(),
            };
        });
    };

    volumeSliderToggleHandler = () => {
        this.setState((prevState) => {
            return {
                isVolumeSliderVisible: !prevState.isVolumeSliderVisible,
            };
        });
    };

    subchannelsToggleHandler = () => {
        this.setState((prevState) => {
            return {
                isSubChannelsVisible: !prevState.isSubChannelsVisible,
            };
        });
    };

    volumeChangedHandler = (newVolume) => {
        this.setState({
            volume: newVolume,
        });

        window?.NpoPlayer?.player?.setVolume(newVolume);
    };

    render() {
        const playButtonClasses = ["player__button player__button--toggle"],
            liveButtonClasses = ["player__button player__button--live"];

        if (!this.state.isAudioPlayerActive && !this.state.retryMode) {
            playButtonClasses.push("is-loading");
        } else if (this.props.isAudioActive && !this.state.retryMode) {
            playButtonClasses.push("is-playing");
        } else {
            playButtonClasses.push("is-paused");
        }

        liveButtonClasses.push(this.props.isVideoActive ? "is-active" : "");

        return (
            <>
                {(this.state.isAppjeVisible || (this.hasAppjeInUrl() && Boolean(this.props.config.appje))) ? (
                    <Appje
                        config={this.props.config.appje}
                        closed={this.appjeToggleHandler}
                    />
                ) : null}

                <div className="player__content">
                    <div className="player__section player__section--channel">
                        {this.props.config.subChannels.length ? (
                            <>
                                <button
                                    className="player__button player__button--channel subchannel-button"
                                    onClick={this.subchannelsToggleHandler}
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.config
                                            .subChannelLabel,
                                    }}
                                />
                                <SubChannelSelector
                                    channels={this.props.config.subChannels}
                                    show={this.state.isSubChannelsVisible}
                                    closed={this.subchannelsToggleHandler}
                                />
                            </>
                        ) : null}
                    </div>

                    <div className="player__section player__section--buttons">
                        {this.props.config.appje !== false ? (
                            <button
                                className="player__button player__button--appje"
                                onClick={this.appjeToggleHandler}
                            >
                                {this.props.config.appje.label || "Appje"}
                            </button>
                        ) : null}
                        <button
                            className={playButtonClasses.join(" ")}
                            onClick={this.resetState}
                        >
                            Speel / Pauze
                        </button>
                        {this.props.playerTokens.live_video ? (
                            <button
                                className={liveButtonClasses.join(" ")}
                                onClick={this.props.onVideoClicked}
                            >
                                Kijk live
                            </button>
                        ) : null}
                    </div>

                    <div className="player__section player__section--volume">
                        <button
                            className="player__button player__button--volume"
                            onClick={this.volumeSliderToggleHandler}
                        >
                            volume
                        </button>
                        <VolumeSlider
                            show={this.state.isVolumeSliderVisible}
                            default={this.state.volume}
                            volumeChanged={this.volumeChangedHandler}
                            closed={this.volumeSliderToggleHandler}
                        />
                    </div>
                    <div
                        id="audio-player"
                        style={{
                            width: 0,
                            height: 0,
                            border: 0,
                            display: "none",
                        }}
                    ></div>
                </div>
            </>
        );
    }
}

Player.propTypes = {
    subChannels: PropTypes.array,
    appjeConfig: PropTypes.array,
};

export default Player;
