import {NMO_ID_KEY, NMO_ID_TTL_DAYS} from './storage/keys';
import {EventType} from './eventType';
import {generateGovolteId, generateNmoId} from './idGenerator';
import {NPOTag, submitEvent} from './npoTag';
import {debug} from './utils/log';
import {getStorage} from './storage';

const TAG = 'PageTracker';

/**
 * Keyword used to determine if a Page is the homepage for NMO DAM
 */
export const PAGE_HOME = 'home';

/**
 * Endpoint used for sending NMO DAM id to Kantar
 */
export const KANTAR_ENDPOINT = 'https://nmonpoendpoint.2cnt.net?vendor=at';

/**
 * Provides tracking context to the {@link PageTracker}.
 *
 * @remarks
 * This context object contains properties shared by all events in a page.
 */
export interface PageContext {
  readonly pageViewId: string;
  readonly nmoid?: string;
  readonly chapter_1?: string;
  readonly chapter_2?: string;
  readonly chapter_3?: string;
  readonly page?: string;
  readonly content_context_id?: string;
  readonly query_context?: string;
  readonly condition?: string;
  readonly custom_label1?: string;
  readonly custom_label2?: string;
  readonly custom_label3?: string;
  readonly custom_label4?: string;
  readonly custom_label5?: string;
  readonly error_code?: string;
  readonly broadcasters?: string;
  readonly program?: string;
}

export interface ClickEventProps {
  /**
   * Name of the click event (e.g. 'kijk later')
   */
  click_name: string;
  /**
   * Type of the click event (e.g. 'navigation', 'action', 'exit', 'download', etc.)
   * @remarks
   * ATInternetPlugin only supports the 4 click types listed below. Events with any other
   * click type will only be sent to Govolte.
   */
  click_type: 'navigation' | 'action' | 'exit' | 'download' | (string & {});
  /**
   * Relative position of the click in the page (optional)
   */
  click_chapter_1?: string;
  /**
   * Relative position of the click in the page (optional)
   */
  click_chapter_2?: string;
  /**
   * Relative position of the click in the page (optional)
   */
  click_chapter_3?: string;
}

/**
 * PageTracker interface used to send events and page views to analytics plugins
 */
export interface PageTracker {
  readonly npoTag: NPOTag;
  readonly pageContext: PageContext;

  /**
   * Log a page view event for the current {@link PageContext}
   */
  pageView(): void;
  /**
   * Log a click event for the current {@link PageContext}
   */
  click(props: Readonly<ClickEventProps>): void;
}

/**
 * Creates a new {@link PageTracker} object with the given context
 *
 * @param npoTag The shared {@link NPOTag} object
 * @param pageContext A {@link PageContext} object
 * @returns A {@link PageTracker} object to log page events
 *
 * @example Minimal usage:
 * ```
 * newPageTracker(npoTag)
 * ```
 * @example Complete usage:
 * ```
 * newPageTracker(npoTag, {
 *   chapter_1: 'programmas',
 *   chapter_2: 'wieisdemol',
 *   chapter_3: 'definale',
 *   page: 'WIDM_123',
 *   content_context_id: 'WIDM_123',
 *   query_context: 'iets over koken',
 *   condition: 'hmpg-A',
 *   custom_label1: 'informatieve content',
 *   custom_label2: 'informatieve content',
 *   custom_label3: 'informatieve content',
 *   custom_label4: 'informatieve content',
 *   custom_label5: 'informatieve content',
 *   error_code: 'error 23: problem authenticating',
 *   broadcasters: 'nos_||_ntr',
 *   program: wieisdemol`,
 * })
 * ```
 */
export function newPageTracker(
  npoTag: NPOTag,
  pageContext?: Omit<PageContext, 'pageViewId' | 'nmoid'>
): PageTracker {
  // Add pageViewId to pageContext
  let ctx: PageContext = {
    ...pageContext,
    pageViewId: generateGovolteId(),
  };
  // Only generate nmoId and send to Kantar if NMO DAM is not disabled
  if (npoTag.getContext().disableNmoDam !== true) {
    // Get stored nmoId or generate a new one
    const nmoId = getNmoId();
    // Send nmoId to Kantar if required
    sendKantarRequest(nmoId);
    // Add nmoId to pageContext
    ctx = {...ctx, nmoid: nmoId};
  }
  return {
    npoTag,
    pageContext: ctx,
    pageView: () => submitEvent(EventType.PAGE_VIEW, npoTag, ctx),
    click: (props: Readonly<ClickEventProps>) =>
      submitEvent(EventType.CLICK, npoTag, {...ctx, ...props}),
  };
}

/**
 * Get NMO id from storage or generate a new one
 * @returns a valid NMO DAM id
 *
 * @remarks
 * If a new id is generated it will be stored persistently
 */
export function getNmoId(): string {
  const currentNmoId = getStorage().get(NMO_ID_KEY);
  if (currentNmoId) {
    debug(TAG, `Using nmoId: ${currentNmoId}`);
    return currentNmoId;
  }
  const nmoId = generateNmoId();
  debug(TAG, `Generated new nmoId: ${nmoId}`);
  setNmoId(nmoId);
  return nmoId;
}

/**
 * Override the NMO id. Normally, this is automatically generated by the SDK.
 */
export function setNmoId(nmoId: string): void {
  getStorage().set(NMO_ID_KEY, nmoId, {
    expires: NMO_ID_TTL_DAYS,
  });
}

let isSessionStorageAvailable: boolean | undefined;
let didSendKantarId: boolean | undefined; // only used when session storage is unavailable

/**
 * Send the given NMO id to Kantar so it has a record of it
 * @param nmoId id generated using {@link generateNmoId}
 *
 * @remarks
 * This serves to transfer the ID to a router (called FocalMeter) that panel members
 * have at home. This router will do an intercept of the HTTP(s) traffic, the endpoint
 * itself does not do anything.
 * The ID is sent once per 'session' (here defined as a browser session) to an endpoint
 * provided by Kantar. To prevent the same id being sent more than once per session, we
 * store a key-value pair in {@link sessionStorage}, keyed by the id itself.
 */
export function sendKantarRequest(nmoId: string): void {
  if (isSessionStorageAvailable === undefined) {
    // first time using it - test it (may be forbidden by browser policy)
    try {
      sessionStorage.getItem(nmoId);
      isSessionStorageAvailable = true;
    } catch (e) {
      // probably blocked
      isSessionStorageAvailable = false;
    }
  }

  // Don't send this id if it has already been sent this session
  // Use session storage is available - use local variable otherwise
  if (isSessionStorageAvailable && sessionStorage.getItem(nmoId) === 'sent') {
    return;
  } else if (!isSessionStorageAvailable && didSendKantarId) {
    return;
  }

  debug(TAG, 'Sending nmoId to Kantar', nmoId);
  // Construct the kantar endpoint
  const endpoint = KANTAR_ENDPOINT + '&cs_fpid=' + nmoId;
  // If sendBeacon is unavailable or sendBeacon fails to queue the event, fall back to loading as an image
  if (
    typeof navigator.sendBeacon === 'undefined' ||
    navigator.sendBeacon(endpoint) === false
  ) {
    const img = new Image();
    img.src = endpoint;
  }
  // Store a value in Session Storage to indicate this has been sent to Kantar
  if (isSessionStorageAvailable) {
    sessionStorage.setItem(nmoId, 'sent');
  }
  didSendKantarId = true;
}
