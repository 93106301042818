import {Event} from '../../event';
import {EventType} from '../../eventType';
import {DOMUtils} from '../../utils/dom';
import {base36} from '../../utils/encoding';
import {mincode} from './mincode';
import {generateGovolteEventId} from './govolteEventIdGenerator';
import {calculateCheckSum, Multimap} from './checksum';

/**
 * @see https://docs.google.com/spreadsheets/d/1bxYDsvbVd2AY2fPVVokY4K65rS2Nm0zg8wlSydYLh0k/edit#gid=0
 * @see https://github.com/npo-topspin/govolte/blob/staging/internal/govolte-schemas/web_schema_v1.go
 */
export interface GovolteEventQueryParams {
  /**
   * json: party_id
   */
  p: string;
  /**
   * json: session_id
   */
  s: string;
  /**
   * json: page_view_id
   */
  v: string;
  /**
   * json: event_id
   */
  e: string;
  /**
   * json: client_timestamp
   *
   * base36
   */
  c: string;
  /**
   * json: new_party
   */
  n: string;
  /**
   * json: first_in_session
   *
   * char
   */
  f: string;
  /**
   * json: checksum
   *
   * base36
   */
  x: string;
  /**
   * json: location
   */
  l: string;
  /**
   * json: referrer
   */
  r?: string;
  /**
   * json: screen_height
   *
   * base36
   */
  j: string;
  /**
   * json: screen_width
   *
   * base36
   */
  i: string;
  /**
   * check https://stackoverflow.com/questions/8785643/what-exactly-is-device-pixel-ratio for background
   * json: screen_pixel_ratio
   */
  k: string;
  /**
   * json: window_height
   *
   * base36
   */
  h: string;
  /**
   * json: viewport_pixel_width
   *
   * base36
   */
  w: string;
  /**
   * json: event_type
   */
  t: string;
  /**
   * Custom parameters (json: custom_parameters)
   * You should use {@link mincode} to parse an instance of {@link GovolteEventCustomParams}
   * to string and assign it to this field.
   */
  u?: string;
}

interface GovolteEventCustomParams {
  brand?: string;
  brandId?: number;
  chapters?: {
    chapter1?: string;
    chapter2?: string;
    chapter3?: string;
  };
  pageName?: string;
  platformType?: string;
  content_context_id?: string;
  user?: {
    npoId?: string;
    profileId?: string;
    type?: string;
    pseudoid?: string;
  };
  query?: string;
  condition?: string;
  platformVersion?: string;
  environment?: string;
  customLabels?: {
    label1?: string;
    label2?: string;
    label3?: string;
    label4?: string;
    label5?: string;
  };
  error?: string;
  program?: string;
  broadcaster?: string;
  screenOrientation?: string;
  nmoid?: string;
  position?: number;
  from?: number;
  length?: number;
  id?: string;
  playerId?: string;
  adLength?: number;
  avType?: string;
  PlayerVersion?: string;
  SKOPlayerVersion?: string;
  liveOffset?: number;
  liveOffsetFrom?: number;
  destination?: {
    recommender?: string;
    contentId?: string;
    index?: number;
    numberDisplayed?: number;
  };
  panel_format?: string;
  panel_position?: number;
  panel?: string;
  offerId?: string;
  sdk_version?: string;
  click_name?: string;
  click_type?: string;
  click_chapter_1?: string;
  click_chapter_2?: string;
  click_chapter_3?: string;
  click_position_x?: number;
  click_position_y?: number;
}

export type GovolteEventQueryParamsWithoutChecksum = Omit<
  GovolteEventQueryParams,
  'x'
>;

export const booleanToChar = (bool: boolean) => (bool ? 't' : 'f');

/**
 * Converts an {@link Event} to {@link GovolteEventQueryParams}
 * @param eventType the {@link EventType} of the event
 * @param event an {@link Event} object
 * @returns the mapped query param object including checksum
 */
export const govolteEventFromEvent = (
  eventType: EventType,
  event: Event
): GovolteEventQueryParams => {
  // Map event properties to query params
  const queryParams = govolteEventQueryParamsFromEvent(eventType, event);

  // Remove any keys which have an undefined values
  (Object.keys(queryParams) as Array<keyof typeof queryParams>).forEach(key => {
    if (queryParams[key] === undefined) {
      delete queryParams[key];
    }
  });

  // Return query params with calculated checksum
  return {
    ...queryParams,
    x: base36(calculateEventChecksum(queryParams)),
  };
};

/**
 * Maps the properties of an {@link Event} object to the Govolte query params
 * @param eventType the {@link EventType} of the event
 * @param event an {@link Event} object
 * @returns the mapped query param object
 *
 * @remarks
 * This also handles inferring a number of Govolte-specific platform properties
 * using the DOMUtils object.
 */
export const govolteEventQueryParamsFromEvent = (
  eventType: EventType,
  event: Event
): GovolteEventQueryParamsWithoutChecksum => {
  const domUtils = DOMUtils();
  const customParams: GovolteEventCustomParams = {
    brand: event.brand,
    brandId: event.brand_id,
    chapters: {
      chapter1: event.chapter_1,
      chapter2: event.chapter_2,
      chapter3: event.chapter_3,
    },
    pageName: event.page,
    platformType: event.platform,
    content_context_id: event.content_context_id,
    user: {
      npoId: event.user_id,
      profileId: event.user_profile,
      type: event.user_subscription,
      pseudoid: event.user_pseudoid,
    },
    query: event.query || event.query_context,
    condition: event.condition,
    platformVersion: event.platform_version,
    environment: event.environment,
    customLabels: {
      label1: event.custom_label1,
      label2: event.custom_label2,
      label3: event.custom_label3,
      label4: event.custom_label4,
      label5: event.custom_label5,
    },
    error: event.error_code,
    program: event.program,
    broadcaster: event.broadcasters,
    screenOrientation: domUtils.getScreenOrientation(),
    nmoid: event.nmoid,
    position: event.stream_position,
    from: event.stream_seek_from,
    length: event.stream_length,
    id: event.stream_id,
    playerId: event.player_id,
    adLength: event.stream_ad_length,
    avType: event.av_type,
    PlayerVersion: event.player_version,
    SKOPlayerVersion: event.sko_player_version,
    liveOffset: event.live_offset,
    liveOffsetFrom: event.live_offset_from,
    destination: {
      recommender: event.recommender,
      contentId: event.target_id,
      index: event.offer_index,
      numberDisplayed: event.total_offers,
    },
    panel_format: event.panel_format,
    panel_position: event.panel_position,
    panel: event.panel_id,
    offerId: event.offer_id,
    sdk_version: event.sdk_version,
    click_name: event.click_name,
    click_type: event.click_type,
    click_chapter_1: event.click_chapter_1,
    click_chapter_2: event.click_chapter_2,
    click_chapter_3: event.click_chapter_3,
    click_position_x: event.click_position_x,
    click_position_y: event.click_position_y,
  };

  return {
    p: event.party_id,
    s: event.session_id,
    v: event.pageViewId,
    e: generateGovolteEventId(event.pageViewId),
    c: base36(new Date(event.client_timestamp).getTime()),
    n: booleanToChar(event.isNewParty),
    f: booleanToChar(event.isFirstInSession),
    l: event.location,
    r: event.referrer,
    j: base36(domUtils.getScreenHeight()),
    i: base36(domUtils.getScreenWidth()),
    k: base36(window.devicePixelRatio),
    h: base36(domUtils.getWindowHeight()),
    w: base36(domUtils.getWindowWidth()),
    t: eventType,
    u: mincode(customParams),
  };
};

/**
 * Calculates a checksum of {@link GovolteEventQueryParamsWithoutChecksum}
 * @param queryParams object containing all defined query params to send to govolte
 * @returns an integer value checksum
 *
 * @remarks
 * Uses checksum calculation method of old govolte.js. We therefore convert the query
 * params object to a {@link Multimap} first so we can re-use the code verbatim.
 * The govolte collector uses this to check that no query params were lost in transit.
 */
export const calculateEventChecksum = (
  queryParams: GovolteEventQueryParamsWithoutChecksum
): number => {
  const multimap: Multimap = {};
  (Object.keys(queryParams) as Array<keyof typeof queryParams>).forEach(key => {
    const value = queryParams[key];
    multimap[key] = value ? [value] : [];
  });
  return calculateCheckSum(multimap);
};
